<template>
  <div class="bg-light" style="min-height: 100vh">
    <b-container>
      <b-row align-h="center">
        <b-col :md="6" :xs="10">
          <b-card class="p-5 shadow" style="top: 50%">
            <h4>亚信后台管理系统</h4>
            <div v-if="show" class="mt-4 mb-3">
              <b-form-group
                  id="input-group-1"
                  label="工号:"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.id"
                    type="text"
                    placeholder="请输入工号..."
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="密码:" label-for="input-2">
                <b-form-input
                    id="input-2"
                    type="password"
                    v-model="form.password"
                    placeholder="请输入密码..."
                    required
                ></b-form-input>
              </b-form-group>
              <div class="mt-4 mx-auto" style="text-align: center">
                <b-button class="mx-auto" @click="onSubmit" variant="dark" style="width:50%;text-align: center">登 录
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-1" ref="modal-1" hide-footer title="修改密码">
      <PasswordBox :id="Number(form.id)"></PasswordBox>
    </b-modal>
  </div>
</template>

<script>
import {login} from '@/api/index'
import jwtDecode from "jwt-decode";
import ModifyPassword from "@/components/Modify/ModifyPassword";
import PasswordBox from "@/components/Modify/passwordBox";

export default {
  name: 'index',
  components: {PasswordBox, ModifyPassword},
  data() {
    return {
      form: {
        id: '',
        password: ''
      },
      foods: [{text: 'Select One', value: null}, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      show: true
    }
  },
  methods: {
    onSubmit() {
      const id = parseInt(this.form.id)
      login(
          id,
          this.form.password
      ).then(res => {
        if (res.data.status) {
          localStorage.setItem('token', res.data.data)
          const staffInfo = JSON.stringify(jwtDecode(res.data.data))
          localStorage.setItem('staffInfo', staffInfo)
          this.$router.push({path: '/main'})
        } else if (res.data.message === '当前密码为默认密码，请先修改密码') {
          this.$confirm('当前密码为默认密码，请先修改密码').then(()=>{
            this.$refs['modal-1'].show()
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
